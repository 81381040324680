"use client";

import { useUser } from "@/contexts/user/hooks/use-user.hook";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import { useTracking } from "@/hooks/tracking/use-tracking";
import Script from "next/script";
import { kilkayaClickTracking } from "./utilities/kilkaya-click-tracking";
import { kilkayaConversionTracking } from "./utilities/kilkaya-conversion-tracking";
import { kilkayaPageTracking } from "./utilities/kilkaya-page-tracking";
export function Kilkaya() {
  const {
    subscribe
  } = useTracking();
  const consent = useCookiebotConsent();
  const {
    model: user
  } = useUser();
  subscribe("click", kilkayaClickTracking);
  subscribe("conversion", model => kilkayaConversionTracking(model, consent));
  subscribe("pageview", model => kilkayaPageTracking({
    consent,
    model,
    user
  }));
  return <>
      <Script id="Kilkaya" data-sentry-element="Script" data-sentry-source-file="Kilkaya.component.tsx">
        {`
var k5aMeta = {
"consent": 0,
"url": "/"
}
`}
      </Script>
      <Script id="KilkayaModule" src="//cl-eu4.k5a.io/6408613675251d0be63b7112.module.js" type="module" data-sentry-element="Script" data-sentry-source-file="Kilkaya.component.tsx" />
      <Script async={true} id="KilkayaNoModule" noModule={true} src="//cl-eu4.k5a.io/6408613675251d0be63b7112.nomodule.js" data-sentry-element="Script" data-sentry-source-file="Kilkaya.component.tsx" />
    </>;
}