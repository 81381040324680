"use client";

import reactDom from "react-dom";

// Pattern taken from: https://nextjs.org/docs/app/api-reference/functions/generate-metadata#resource-hints
export function PreloadResources() {
  // These resources have been identified by running:
  // https://web.dev/optimizing-content-efficiency-loading-third-party-javascript/#chrome-devtools-performance-panel
  // several times and then "Group by Subdomain". It is stated several places that you should max
  // preconnect to six resources, so the ones with the largest hit has been chosen
  reactDom.preconnect("https://www.gstatic.com");
  reactDom.preconnect("https://consent.cookiebot.com");
  reactDom.preconnect("https://api.eu1.exponea.com");
  reactDom.preconnect("https://cl-eu4.k5a.io");

  // Fallback for browsers not supporting preconnect
  reactDom.prefetchDNS("https://www.gstatic.com");
  reactDom.prefetchDNS("https://consent.cookiebot.com");
  reactDom.prefetchDNS("https://api.eu1.exponea.com");

  return null;
}
